import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import Headings from "../components/Headings.js";

import { termsHeroDetails } from "../constants";

import styles from "../styles/style.js";

const TermsAndConditions = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: allFile(
        filter: { name: { in: "hero-brown-books-18th-edition" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 2.99
            )
          }
        }
      }
    }
  `);

  //console.log("Data - Sponsorship: ", data);

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Intact Electrical Ltd - Terms and conditions",
        "url":  "https://intact-electrical.co.uk/terms-and-conditions",
        "logo": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif"
      })}
    </script>
  )

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = termsHeroDetails;

  return (
    <>
      <Seo       
        title="Terms & conditions"
        description="Data protection, How and when we collect your personal data, What we collect, Sharing your personal data, Know your rights"
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <div className={`bg-[#ffffff]  ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section className="mt-6">
            <Headings
              titleTop="The Rules"
              titleBottom="& regulations"
              description="These Terms & Conditions were last updated in September 2023, Intact Electrical Ltd reserve the right to amend or update our terms and conditions without prior notice."
            />
            <div className="mt-4 mb-4">
            
              <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]  leading-[18px] w-full pb-4 mt-8">Copyright Notice</h2>

              <p className="text-base text-gray-700 md:text-lg">
                All material on this site is copyright of Intact Electrical Ltd or preauthorised third parties working on behalf of Intact Electrical Ltd. 
                You may view the contents of the website on screen, and you may print one copy of any article contained on the site for your personal use. 
                If you require multiple printed copies of any of the website content, please contact us.</p>
              
              <p className="text-base text-gray-700 md:text-lg mt-4">
              No part of this site may be copied, reproduced, published, modified, transmitted, or broadcast without the prior permission of Intact Electrical Ltd.
              </p>

              <p className="text-base text-gray-700 md:text-lg mt-4">
                The Intact Electrical Ltd logo is subject to copyright and may not be used without prior consent. All digital and print content across our website 
                also belong to Intact Electrical Ltd and are subject to the same copyright restrictions.
              </p>

              <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]  leading-[18px] w-full pb-4 mt-8">Liability Disclaimer</h2>

              <p className="text-base text-gray-700 md:text-lg">
                Intact Electrical provide the content on our website for your own use, not for any commercial, business or re-sale purposes, and you agree that we have no liability 
                to you for any injury, loss of profit, loss of business, business interruption or loss of business opportunity.
              </p>

              <p className="text-base text-gray-700 md:text-lg mt-4">
                Nothing in these terms and conditions shall exclude or limit any person's liability where it would be unlawful to do so. This includes for death or personal injury caused 
                by a person's negligence, or any person's liability for fraud.
              </p>

              <p className="text-base text-gray-700 md:text-lg mt-4">
                Except as specified in these Terms, all express or implied conditions, terms, representations, or warranties (whether implied by statute or otherwise) are hereby excluded 
                to the maximum extent permitted by law.
              </p>

              <p className="text-base text-gray-700 md:text-lg mt-4">
                These Terms do not and shall not affect your statutory rights as a consumer.
              </p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
